<template>
    <ListSmsLog></ListSmsLog>
</template>
  
  <script>

import ListSmsLog from "@core/layouts/components/admin-components/ListSmsLog.vue";

export default {
    name: "Reservations",

    components: {
        ListSmsLog,
    }
}
</script>
  
  <style scoped>
  </style>